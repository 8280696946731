@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf');
}
.contact {
	width:100%;
	height: 50vh;
	margin: 5% 0 0 0;
	color: #e3e3e3;
	font-size:18px;
	position:relative;
	text-align: center;
}
.contactForm {
	height: 100% !important;
}

@media screen and (max-width: 820px) and (orientation: portrait){
	.contact {
		width:100%;
		margin:0;
		margin-top:-10%;
		font-size:16px;
	}
	p#top {
		font-size:20px;
	}
	span#top {
		font-size:20px;
	}
}