@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf');
}
.mainText {
	width: 60%;
	height:30%;
	margin:15% 20% 0 20%;
	background-color: none;
	border: none;
	text-align: center;
	color: #000;
	font-size:50px;
}
.callToAction {
	width:40%;
	height:50px;
	margin:40px 30% 0 30%;
	background-color: #2B6DB6;
	border-radius:5px;
	text-align: center;
	vertical-align: middle;
	color:#FFF;
	font-size:20px;
	line-height: 50px;
}
.callToAction:hover {
	background-color: #41414d;
}

@media screen and (1920 <= width <= 3500){
	.mainText {
		font-size:33px;
		width:70%;
	}
}
@media screen and (max-width: 820px) and (orientation: portrait){
	.mainText {
		font-size:22px;
		width:80%;
		margin:0 10% 0 10%;
	}
	.callToAction {
		width:80%;
		font-size:16px;
		margin:40px 10% 0 10%;
	}
}
