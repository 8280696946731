@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf');
}
.about {
	width:70%;
	margin: 5% 15% 0 15%;
	color: #000;
	font-size:18px;
	position:relative;
	text-align: center;
}
p#top {
	font-size:26px;
	color: #000;
	text-align: justify;
	text-align-last: left;
}
span#top {
	color: #000;
	font-size:26px;

}
p#middle {
	text-align: justify;
	text-align-last: left;
}
span#middle {
	color: #2B6DB6;
	font-weight: bold;
}
p#bottom {
	text-align: justify;
	text-align-last: left;
}

@media screen and (max-width: 820px) and (orientation: portrait){
	.about {
		width:100%;
		margin:0;
		margin-top:-10%;
		font-size:16px;
	}
	p#top {
		font-size:20px;
	}
	span#top {
		font-size:20px;
	}
}