@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf');
}
.footer {
	width: 100vw;
	height:5vh;
	background-color: none;
	border: none;
	text-align: center;
	color: #000;
	margin-top:10%;
	margin-bottom:5%;
}
@media screen and (max-width: 820px) and (orientation: portrait){
	.footer {
		margin-top:10%;
		margin-bottom:10%;
	}
}