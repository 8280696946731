@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf');
}
.header {
	width: 100vw;
	height:20vh;
	background-color: none;
	border: none;
	position: relative;
}
img.logoFull {
	max-width: 15vw;
	height: 7.5vh;
}
img.logoFull:hover {
	opacity:0.45;
}
a.logoFull {
	display: inline-block;
	position: absolute;
	top:6vh;
	left:10%;
}
.links {
	position: absolute;
	top:8vh;
	right:10%;
	width:60%;
	text-align: right;
}
.dropdownLink {
	font-family: 'Roboto';
	font-size: 5%;
	color: #000;
	display: inline-block;
	font-size: 30px;
	text-decoration: none;
	margin-left:6%;
}
.staticLink {
	font-family: 'Roboto';
	font-size: 5%;
	color: #000;
	display: inline-block;
	font-size:30px;
	text-decoration: none;
	margin-left:6%;
}
.dropdownLink:hover, .staticLink:hover {
	color:#2B6DB6; 
}
.subLink {
	font-family: 'Roboto';
	font-size: 22px;
	line-height: 50px;
	color: #000;
	display: inline-block;
	text-decoration: none;
	margin:0 2% 0 2%;
}
.subLink:hover {
	color:#2B6DB6;
}
.subMenu {
	position: absolute;
	top:15vh;
	right:10%;
	width:40%;
	height:50px;
	background-color: #FFF;
	text-align:center;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	/*box-shadow: 0 -5px 5px #1c1c26;*/
	z-index: 10;
}
#hidden {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s linear 200ms, opacity 200ms;
}
#visible {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s linear 0s, opacity 200ms;
}

@media screen and (max-width: 820px) and (orientation: portrait){
	.links {
		top:5vh;
	}
	a.logoFull{
		top:2vh;
		left:5vw;
	}
	img.logoFull {
		max-width: 90vw;
		height: 50px;
	}
	.mobileMenu {
		top:10vh;
		left:12.5vw;
		width:75vw;
		position: absolute;
		z-index: 10;
	}
	#open {
		height:410px;
	}
	#closed {
		height:50px;
	}
	.mobileMenuButton {
		width:100%;
		height:50px;
		background-color: #1c1c26;
		line-height: 50px;
		color:#e3e3e3;
		text-align:center;
		border-radius: 5px;

	}
	.mobileMenuButton#show{
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	.staticMobileLink#show {
		visibility: visible;
	}
	.staticMobileLink#hide {
		visibility: hidden;
	}
	.staticMobileLink {
		width:100%;
		height:60px;
		color: #e3e3e3;
		background-color: #41414d;
		text-align:center;
		border-bottom:1px solid #111117;
		line-height: 60px;
	}
	.bottom {
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
}