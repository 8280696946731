@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.ttf');
  font-weight:bold;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf');
  font-weight:400;
}
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#FFF !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  width:100%;
}
*:focus {
    outline: 0 !important;
}
.content {
  width:100vw;
  height:80vh;
  position: absolute;
  top:10vh;
}

@media screen and (max-width: 900px){
  .content {
    width:90%;
    left:5%;
    height:140%;
    margin-top:45%;
  }
}